import React, { useContext } from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import { userContext } from '../context/user-provider'
import useTranslation from '../hooks/use-translation'
import { Button } from '@material-ui/core'

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ad5389; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #3c1053,
    #ad5389
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #3c1053,
    #ad5389
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
`

const Title = styled.h2`
  margin: 10px;
`
const Bread = styled.p`
  font-size: 18px;
`
const Logout = () => {
  const { user, logout } = useContext(userContext)
  const t = useTranslation()
  return (
    <Layout>
      <Wrapper>
        <div className='animate-in'>
          {!user ? (
            <>
              <Title>{t`Successfully logged out`}!</Title>
              <Bread>
                {t`Make sure to close the browser completely to logout from all sessions`}
              </Bread>
            </>
          ) : (
            <>
              <Title>{t`Something went wrong`}..</Title>
              <Bread>{t`Try again`}!</Bread>
              <Button
                href='https://sso.i-chalmers.se/Shibboleth.sso/Logout?return=https://sso.i-chalmers.se/api/logout.callback'
                target='_blank'
                variant='contained'
                onClick={logout}
              >
                {t`Logout`}
              </Button>
            </>
          )}
        </div>
      </Wrapper>
    </Layout>
  )
}

export default Logout
